 

.img-hover {
  transition: .3s ease-in-out;
  overflow: hidden;
}

.img-hover:hover {
  transform: scale(1.1);
}
